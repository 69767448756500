<template>
  <div class="about">

    <h1>Settings for {{name}}</h1>
    
    <ui-button raised @click="log_out">Log Out</ui-button>
  </div>
</template>

<script>
export default {
  data(){
    return {
      know_users: []
    }
  },
  computed:{
    name(){
      return this.$store.state.users.profile.name
    }
  },
  methods: {
    log_out(){
      this.$store.dispatch('users/log_out').then(()=>{
        this.$store.commit('logout')
        this.$router.push('/')
      })
    },
  },
  created(){
    // this.$store.dispatch('users/get_friends', {user_ids:this.know_users})
  }
}
</script>
